<template>
    <v-dialog v-model="dialog" width="500" no-click-animation>
        <v-card>
            <v-card-title>Change Billing Cycle</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-layout column>
                    <v-flex md12>
                        The future recurring amount will be changed after you changed the billing cycle.
                        <span v-if="invoice">
                            There is an invoice #{{invoice.id}} (${{invoice.total}}).We will cancel it and generate a new invoice after you changed the billing cycle.
                        </span>
                        <br>
                        <span v-if="has_paypal_subscription">
                            After you change the billing cycle, the future recurring amount will be changed, followed by your PayPal subscription cancellation.
                            If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.
                        </span>
                    </v-flex>
                    <v-flex md12>
                        <v-radio-group  v-if="items" v-model="formData.price_id" >
                            <v-radio  v-for="(item,index) in items" :key="index" :value="item.id" color="primary">
                                <template v-slot:label>
                                    <span>
                                          {{item.duration_description}} : $ {{item.price_base}}/month
                                    </span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        <div v-else  style="display: flex;align-items: center;justify-content:center;min-height: 132px;">
                            <v-progress-circular  indeterminate  color="blue"></v-progress-circular>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small class="ml-3 text-none" color="blue darken-1" flat @click="close">Cancel</v-btn>
                <v-btn small class="text-none" color="primary" :loading="loading" @click="ok">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import dialogMixin from '@/module/mixins/loading.mixin'

    export default {
        name: "BillingCycle",
        mixins: [dialogMixin],
        data() {
            return {
                formData: {
                    price_id: '',
                    uuid:''
                },
                invoice:null,
                old_price_duration:'',
                has_paypal_subscription: false,
                items: null,
            }
        },
        watch: {
            dialog(){
              if (!this.dialog) {
                  this.formData.price_id = ''
                  this.formData.uuid = ''
                  this.items = null
              }
            }
        },
        methods: {
            open(item) {
                this.initBillingCycle(item.plan.uuid)
                this.formData.uuid = item.uuid
                this.invoice = item.invoice
                this.has_paypal_subscription = item.has_paypal_subscription
                this.old_price_duration = item.price.duration
                this.dialog = true
            },
            initBillingCycle(id) {
                // /packages/{package_uuid}/get_billing_cycle_condition/
                this.$http.get(`/plans/${id}/`).then(res => {
                    this.items = res.prices_set.filter(item => {
                        return item.duration!==this.old_price_duration
                    })
                })
            },
            $validator() {
                return !!this.formData.price_id
            }
        },
    }
</script>

<style lang="scss">

</style>