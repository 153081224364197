var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.packageStatus === "Deploy Failed"
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mr-3 text-none",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$refs.deployconfirm.open({
                                    uuid: _vm.app.uuid
                                  })
                                }
                              }
                            },
                            on
                          ),
                          [_vm._v("Re-deploy\n            ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2731138770
              )
            },
            [_c("span", [_vm._v("Click to re-deploy your application.")])]
          )
        : _vm._e(),
      ["Suspended", "Active", "Pending"].indexOf(_vm.packageStatus) !== -1 &&
      _vm.app.invoice
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mr-3 text-none",
                              attrs: { color: "primary" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.packageStatus === "Pending"
                                    ? "Pay Now"
                                    : "Renew"
                                )
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$refs.renewconfirm.open(_vm.app)
                                }
                              }
                            },
                            on
                          )
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                166323398
              )
            },
            [
              _vm.packageStatus === "Pending"
                ? _c("span", [
                    _vm._v(
                      "Pay invoice #" +
                        _vm._s(_vm.app.invoice.id) +
                        " ($" +
                        _vm._s(_vm.app.invoice.total) +
                        ") to activate my application "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "Unpaid invoice #" +
                        _vm._s(_vm.app.invoice.id) +
                        " ($" +
                        _vm._s(_vm.app.invoice.total) +
                        ")"
                    )
                  ])
            ]
          )
        : _vm._e(),
      ["Active", "Pending"].indexOf(_vm.packageStatus) !== -1 &&
      _vm.checkInfoCompleted() &&
      !_vm.app.invoice
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$refs.renewconfirm.open(_vm.app)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            { staticStyle: { display: "inline-block" } },
                            on
                          ),
                          [
                            _c("v-btn", {
                              staticClass: "mr-3 text-none",
                              attrs: {
                                color: "primary",
                                disabled: _vm.clientInfo.status !== "Good"
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.packageStatus === "Pending"
                                    ? "Pay Now"
                                    : "Renew"
                                )
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$refs.renewconfirm.open(_vm.app)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                682097162
              )
            },
            [
              _vm.clientInfo.status !== "Good"
                ? _c("span", [
                    _vm._v(
                      "Please wait while we verify your account. It may take 1-2 hours."
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "Renew your service to extend the due date of your application."
                    )
                  ])
            ]
          )
        : _vm._e(),
      ["Suspended", "Active"].indexOf(_vm.packageStatus) !== -1 &&
      !_vm.checkInfoCompleted()
        ? [
            _c(
              "v-btn",
              {
                staticClass: "mr-3 text-none",
                attrs: {
                  title:
                    "Please complete billing information to activate your paid account.",
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.updateBillingInfo($event)
                  }
                }
              },
              [_vm._v("\n            Update Billing Info\n        ")]
            )
          ]
        : _vm._e(),
      ["Deploy Failed"].indexOf(_vm.packageStatus) !== -1 ||
      (_vm.packageStatus === "Suspended" && !_vm.app.invoice)
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            { staticStyle: { display: "inline-block" } },
                            on
                          ),
                          [
                            _c("v-btn", {
                              staticClass: "mr-3 text-none",
                              attrs: {
                                color: "primary",
                                disabled:
                                  _vm.packageStatus === "Cancelled" &&
                                  !_vm.app.deploy_status
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.packageStatus === "Cancelled"
                                    ? "Request Restoration"
                                    : "Open New Ticket"
                                )
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$refs.ticketconfirm.open(_vm.app)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3202506160
              )
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.packageStatus === "Deploy Failed"
                      ? "Report the issue and ask for help."
                      : _vm.packageStatus === "Cancelled" &&
                        !_vm.app.deploy_status
                      ? "The application can not be restored because there are no available backups."
                      : "Request to restore Your Application"
                  )
                }
              })
            ]
          )
        : _vm._e(),
      _vm.packageStatus === "Cancelled"
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mr-3 text-none",
                              attrs: {
                                color: "primary",
                                disabled: !_vm.app.backup_info
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.$refs.restoreconfirm.open(_vm.app)
                                }
                              }
                            },
                            on
                          ),
                          [_vm._v("Restore\n            ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3134680941
              )
            },
            [_c("span", [_vm._v("Click to restore your application.")])]
          )
        : _vm._e(),
      _c("Confirm", {
        ref: "deployconfirm",
        on: { confirm: _vm.redeploy },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v("\n                Re-deploy the APP\n            ")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    "\n                Deploying the app may take a few minutes. If it failed, please contact us via a ticket.\n                Do you want to continue?\n            "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Confirm", {
        ref: "renewconfirm",
        on: { confirm: _vm.renew },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.getPackageStatus(options) === "Pending"
                        ? "Pay Invoice #" +
                            (options.invoice ? options.invoice.id : "")
                        : "Renew my service"
                    )
                  }
                })
              ]
            }
          },
          {
            key: "content",
            fn: function(ref) {
              var options = ref.options
              return [
                _vm.getPackageStatus(options) === "Pending"
                  ? _c("p", [
                      _vm._v(
                        "\n                Your application will be active in 1-2 minutes and the due date will be extended to\n                " +
                          _vm._s(_vm.formatDate(options.invoice_end_date)) +
                          " after your payment\n            "
                      )
                    ])
                  : _vm.clientInfo.status === "Good" && options.invoice
                  ? _c("p", [
                      _vm._v(
                        "\n                You are about to pay invoice #" +
                          _vm._s(options.invoice.id) +
                          "($" +
                          _vm._s(options.invoice.total) +
                          ") to extend the due\n                date of your application.\n                The due date will be " +
                          _vm._s(_vm.formatDate(options.invoice_end_date)) +
                          " after your payment.\n            "
                      )
                    ])
                  : _vm.clientInfo.status === "Good" && !options.invoice
                  ? _c("p", [
                      _vm._v(
                        "\n                We will generate a new invoice($" +
                          _vm._s(options.recurring_amount) +
                          ") to renew your service.\n                The due date will be extended to " +
                          _vm._s(_vm.formatDate(options.invoice_end_date)) +
                          " after your payment.\n            "
                      )
                    ])
                  : _c("p", [
                      _vm._v(
                        "\n                You are about to renew your service by following the steps below:"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Step 1. Complete your billing information."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Step 2. Information verification(This may take 1-2 hours.)"
                      ),
                      _c("br"),
                      _vm._v("\n                Step 3. Make payment."),
                      _c("br"),
                      _vm._v(
                        "\n                After your payment, the due date of your application will be extended to\n                " +
                          _vm._s(_vm.formatDate(options.invoice_end_date)) +
                          ". Do you want to complete your billing information now?\n            "
                      )
                    ])
              ]
            }
          }
        ])
      }),
      _c("Confirm", {
        ref: "ticketconfirm",
        attrs: { maxWidth: "500px" },
        on: { confirm: _vm.openTicket },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("span", [
                  _vm._v("\n                Open New Ticket\n            ")
                ])
              ]
            }
          },
          {
            key: "content",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("p", [
                  _vm._v(
                    "\n                Please confirm and click Send in the next ticket panel. Our support staff will respond to you in 1-2\n                hours.\n            "
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("Confirm", {
        ref: "restoreconfirm",
        attrs: { maxWidth: "500px" },
        on: { confirm: _vm.restoreRequest },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("span", [
                  _vm._v(
                    "\n                Restore Your Application\n            "
                  )
                ])
              ]
            }
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    "\n                Are you sure you want to restore the application?\n            "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Confirm", {
        ref: "restoreNextConfirm",
        on: { confirm: _vm.restoreNext },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      options.process_type === "need_pay"
                        ? "Make Payment"
                        : "Complete Billing Information"
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "content",
            fn: function(ref) {
              var options = ref.options
              return [
                options.process_type === "need_pay"
                  ? _c("p", [
                      _vm._v(
                        "\n                Once the invoice for the next billing cycle has been paid,\n                your application will be automatically restored. The restoration may take several minutes.\n            "
                      )
                    ])
                  : _vm.clientInfo.status !== "Uncertain"
                  ? _c("p", [
                      _vm._v(
                        "\n                Billing information is needed for us to verify your identity(normally 1-2 hrs).\n                After you pass the verification, the plan fee for the next billing cycle should be paid before the restoration.\n            "
                      )
                    ])
                  : _c("p", [
                      _vm._v(
                        "\n                Some information on your account seems to be incorrect.\n                Please update your information to help us verify your identity. The verification may take 1-2 hours.\n            "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }