var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Edit App Name")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "insert",
                      rawName: "v-insert",
                      value: {
                        tagName: "input",
                        attrs: { style: "display:none", type: "text" }
                      },
                      expression:
                        "{tagName:'input',attrs:{style:'display:none',type:'text'}}"
                    }
                  ],
                  ref: "form"
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                _vm.rules.required,
                                function(value) {
                                  return (
                                    !value ||
                                    value.length <= 25 ||
                                    "Maximum 25 characters"
                                  )
                                }
                              ]
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3 text-none",
                  attrs: { small: "", color: "blue darken-1", flat: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { small: "", color: "primary", loading: _vm.loading },
                  on: { click: _vm.ok }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }