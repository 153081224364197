<!--App list page button area functions are handled in this component-->

<template>
    <div >
        <v-tooltip top v-if="packageStatus === 'Deploy Failed'">
            <template v-slot:activator="{ on }">
                <v-btn class="mr-3 text-none"  v-on="on" color="primary"
                       @click.stop.prevent="$refs.deployconfirm.open({uuid:app.uuid})">Re-deploy
                </v-btn>
            </template>
            <span>Click to re-deploy your application.</span>
        </v-tooltip>
        <v-tooltip top
                   v-if="['Suspended','Active','Pending'].indexOf(packageStatus)!==-1 &&app.invoice">
            <template v-slot:activator="{ on }">
                <v-btn class="mr-3 text-none"  v-on="on" color="primary"
                       @click.stop.prevent="$refs.renewconfirm.open(app)"
                       v-text="packageStatus === 'Pending'?'Pay Now':'Renew'"></v-btn>
            </template>
            <span v-if="packageStatus === 'Pending'">Pay invoice #{{app.invoice.id}} (${{app.invoice.total}}) to activate my application </span>
            <span v-else>Unpaid invoice #{{app.invoice.id}} (${{app.invoice.total}})</span>
        </v-tooltip>
        <v-tooltip top
                   v-if="['Active','Pending'].indexOf(packageStatus)!==-1  && checkInfoCompleted() &&!app.invoice"
                   @click.stop.prevent="$refs.renewconfirm.open(app)">
            <template v-slot:activator="{ on }">
                <div v-on="on" style="display: inline-block">
                    <v-btn class="mr-3 text-none"
                           color="primary"
                           :disabled="clientInfo.status!=='Good'"
                           @click.stop.prevent="$refs.renewconfirm.open(app)"
                           v-text="packageStatus === 'Pending'?'Pay Now':'Renew'">
                    </v-btn>
                </div>
            </template>
            <span v-if="clientInfo.status!=='Good'">Please wait while we verify your account. It may take 1-2 hours.</span>
            <span v-else>Renew your service to extend the due date of your application.</span>
        </v-tooltip>
        <template v-if="['Suspended','Active'].indexOf(packageStatus)!==-1 && !checkInfoCompleted()">
            <v-btn class="mr-3 text-none" :title="`Please complete billing information to activate your paid account.`" color="primary"
                   @click.stop.prevent="updateBillingInfo">
                Update Billing Info
            </v-btn>
        </template>
        <v-tooltip top
                   v-if="['Deploy Failed'].indexOf(packageStatus)!==-1 ||(packageStatus === 'Suspended'&& !app.invoice)">
            <template v-slot:activator="{ on }">
                <div v-on="on" style="display: inline-block">
                    <v-btn class="mr-3 text-none"  color="primary"
                           @click.stop.prevent="$refs.ticketconfirm.open(app)"
                           :disabled="packageStatus==='Cancelled' && !app.deploy_status"
                           v-text="packageStatus==='Cancelled'?'Request Restoration':'Open New Ticket'">
                    </v-btn>
                </div>
            </template>
            <span v-text="packageStatus === 'Deploy Failed'?'Report the issue and ask for help.':packageStatus==='Cancelled' && !app.deploy_status?'The application can not be restored because there are no available backups.':'Request to restore Your Application'"></span>
        </v-tooltip>
        <v-tooltip top v-if="packageStatus === 'Cancelled'">
            <template v-slot:activator="{ on }">
                <v-btn class="mr-3 text-none"  v-on="on" color="primary"
                       :disabled="!app.backup_info"
                       @click.stop.prevent="$refs.restoreconfirm.open(app)">Restore
                </v-btn>
            </template>
            <span>Click to restore your application.</span>
        </v-tooltip>
        <Confirm ref="deployconfirm" @confirm="redeploy">
            <template v-slot:title>
                <span>
                    Re-deploy the APP
                </span>
            </template>
            <template v-slot:content>
                <p>
                    Deploying the app may take a few minutes. If it failed, please contact us via a ticket.
                    Do you want to continue?
                </p>
            </template>
        </Confirm>
        <Confirm ref="renewconfirm" @confirm="renew">
            <template v-slot:title="{options}">
                <span v-text="getPackageStatus(options) === 'Pending'?`Pay Invoice #${options.invoice?options.invoice.id:''}`:'Renew my service'"></span>
            </template>
            <template v-slot:content="{options}">
                <p v-if="getPackageStatus(options) === 'Pending'">
                    Your application will be active in 1-2 minutes and the due date will be extended to
                    {{formatDate(options.invoice_end_date)}} after your payment
                </p>
                <p v-else-if="clientInfo.status==='Good' && options.invoice">
                    You are about to pay invoice #{{options.invoice.id}}(${{options.invoice.total}}) to extend the due
                    date of your application.
                    The due date will be {{formatDate(options.invoice_end_date)}} after your payment.
                </p>
                <p v-else-if="clientInfo.status==='Good' && !options.invoice">
                    We will generate a new invoice(${{options.recurring_amount}}) to renew your service.
                    The due date will be extended to {{formatDate(options.invoice_end_date)}} after your payment.
                </p>
                <p v-else>
                    You are about to renew your service by following the steps below:<br>
                    Step 1. Complete your billing information.<br>
                    Step 2. Information verification(This may take 1-2 hours.)<br>
                    Step 3. Make payment.<br>
                    After your payment, the due date of your application will be extended to
                    {{formatDate(options.invoice_end_date)}}. Do you want to complete your billing information now?
                </p>
            </template>
        </Confirm>
        <Confirm ref="ticketconfirm" maxWidth="500px" @confirm="openTicket">
            <template v-slot:title="{options}">
                <span>
                    Open New Ticket
                </span>
            </template>
            <template v-slot:content="{options}">
                <p>
                    Please confirm and click Send in the next ticket panel. Our support staff will respond to you in 1-2
                    hours.
                </p>
            </template>
        </Confirm>
        <Confirm ref="restoreconfirm" maxWidth="500px" @confirm="restoreRequest">
            <template v-slot:title="{options}">
                <span>
                    Restore Your Application
                </span>
            </template>
            <template v-slot:content>
                <p>
                    Are you sure you want to restore the application?
                </p>
            </template>
        </Confirm>

        <Confirm ref="restoreNextConfirm" @confirm="restoreNext">
            <template v-slot:title="{options}">
                <span>{{options.process_type==='need_pay'?'Make Payment':'Complete Billing Information'}}</span>
            </template>
            <template v-slot:content="{options}">
                <p v-if="options.process_type==='need_pay'">
                    Once the invoice for the next billing cycle has been paid,
                    your application will be automatically restored. The restoration may take several minutes.
                </p>
                <p v-else-if="clientInfo.status !== 'Uncertain'">
                    Billing information is needed for us to verify your identity(normally 1-2 hrs).
                    After you pass the verification, the plan fee for the next billing cycle should be paid before the restoration.
                </p>
                <p v-else>
                    Some information on your account seems to be incorrect.
                    Please update your information to help us verify your identity. The verification may take 1-2 hours.
                </p>
            </template>
        </Confirm>
    </div>
</template>

<script>
    import {mapGetters,mapState} from 'vuex'
    import Confirm from '@/components/Confirm.vue'
    import {formatDate} from '@/module/utils/date'

    export default {
        name: "AppBtn",
        components:{Confirm},
        props: {
            app: {
                require: true,
                type: Object
            }
        },
        data() {
            return {
            }
        },
        computed: {
            packageStatus() {
                return this.getPackageStatus(this.app)
            },
            ...mapState('client',['clientInfo']),
            ...mapGetters('client', ['checkInfoCompleted']),
            ...mapGetters('application', ['getPackageStatus']),
        },
        methods: {
            formatDate,
            restoreNext(formData) {
                if (formData.process_type === 'need_complete_info') {
                    if (!this.checkInfoCompleted()||this.clientInfo.status === 'Uncertain') {
                        this.$router.push('/profile/account')
                    }
                } else if (formData.process_type === 'need_pay') {
                    this.$router.push(`/invoice-detail/${formData.invoice_uuid}`)
                }
                return Promise.resolve()
            },
            restoreRequest(formData) {
                return this.$http.post(`/packages/${formData.uuid}/restore-deploy/`).then(res => {
                    if (res.process_type === 'free_trial') {
                        this.$emit('success')
                        this.$message.success('Your application is being restored.')
                    } else {
                        this.$refs.restoreNextConfirm.open(res)
                    }
                }).catch((err) => {
                    this.$message.error(err.detail)
                })
            },

            redeploy(app) {
                return this.$http.post(`/packages/${app.uuid}/redeploy/`).then(() => {
                    this.$emit('deploy',app.uuid)
                    this.$message.success('The system is deploying the application. It may take 3-5 minutes.')
                }).catch(() => {
                    this.$message.error('Deployment request submission failed. Please contact us via a ticket.')
                })
            },
            updateBillingInfo() {
                this.$root.billingCpn.open()
            },
            openTicket(app) {
                this.$router.push({
                    path: '/ticket',
                    query: {
                        status: this.packageStatus,
                        name: app.name
                    },
                })
                return Promise.resolve()
            },

            renew(app) {
                if (app.invoice) {
                    this.$router.push(`/invoice-detail/${app.invoice.uuid}`)
                    return Promise.resolve()
                } else {
                    return this.$http.put(`/packages/${app.uuid}/renew/`).then((res) => {
                        this.$router.push(`/invoice-detail/${res.invoice.uuid}`)
                    }).catch(() => {
                        this.$message.error('error')
                    })
                }
            },
        }
    }
</script>

<style lang="scss">

</style>