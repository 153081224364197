var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Change Billing Cycle")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c("v-flex", { attrs: { md12: "" } }, [
                    _vm._v(
                      "\n                    The future recurring amount will be changed after you changed the billing cycle.\n                    "
                    ),
                    _vm.invoice
                      ? _c("span", [
                          _vm._v(
                            "\n                        There is an invoice #" +
                              _vm._s(_vm.invoice.id) +
                              " ($" +
                              _vm._s(_vm.invoice.total) +
                              ").We will cancel it and generate a new invoice after you changed the billing cycle.\n                    "
                          )
                        ])
                      : _vm._e(),
                    _c("br"),
                    _vm.has_paypal_subscription
                      ? _c("span", [
                          _vm._v(
                            "\n                        After you change the billing cycle, the future recurring amount will be changed, followed by your PayPal subscription cancellation.\n                        If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.\n                    "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { md12: "" } },
                    [
                      _vm.items
                        ? _c(
                            "v-radio-group",
                            {
                              model: {
                                value: _vm.formData.price_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "price_id", $$v)
                                },
                                expression: "formData.price_id"
                              }
                            },
                            _vm._l(_vm.items, function(item, index) {
                              return _c("v-radio", {
                                key: index,
                                attrs: { value: item.id, color: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                      " +
                                                _vm._s(
                                                  item.duration_description
                                                ) +
                                                " : $ " +
                                                _vm._s(item.price_base) +
                                                "/month\n                                "
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "min-height": "132px"
                              }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "blue" }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3 text-none",
                  attrs: { small: "", color: "blue darken-1", flat: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { small: "", color: "primary", loading: _vm.loading },
                  on: { click: _vm.ok }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }