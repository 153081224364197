<template>
    <v-dialog v-model="dialog" width="500" no-click-animation>
        <v-card>
            <v-card-title>Edit App Name</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" v-insert="{tagName:'input',attrs:{style:'display:none',type:'text'}}">
                    <v-layout column>
                        <v-flex md12>
                                <v-text-field
                                        v-model="formData.name"
                                        :rules="[rules.required,value => !value || value.length <= 25 || 'Maximum 25 characters']"
                                >
                                </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small class="ml-3 text-none" color="blue darken-1" flat @click="close">Cancel</v-btn>
                <v-btn small class="text-none" color="primary" :loading="loading" @click="ok">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import dialogMixin from '@/module/mixins/loading.mixin'
    import rules from '@/module/rules/index.js'

    export default {
        name: "BillingCycle",
        mixins: [dialogMixin],
        data() {
            return {
                rules: {...rules},
                formData: {
                    name:"",
                    uuid:''
                },
            }
        },
        methods: {
            open(item) {
                this.formData.uuid = item.uuid
                this.formData.name = item.name
                this.dialog = true
            },
            $validator() {
                return this.$refs.form.validate()
            },
        }
    }
</script>

<style lang="scss">

</style>